<div
    class="w-full h-full pt-[150%] rounded-lg overflow-hidden shadow-lg relative group transition-all duration-1000 ease-in-out md:hover:cursor-pointer z-10"
    (mouseenter)="isHovering = true"
    (mouseleave)="isHovering = false"
>
    <div
        class="flex flex-col bg-white bottom-0 top-0 left-0 z-20 absolute right-0 justify-between items-stretch rounded-lg"
    >
        <!-- Streamer Image -->
        <img
            class="h-full rounded-lg absolute w-full z-10 right-0 top-0 bottom-0 left-0 object-cover animate-opacity"
            src="{{ data.thumb || userPhotoConverter(data.id) }}"
            alt="Streamer Image"
        />
        <div
            class="absolute inset-0 bg-black bg-opacity-50 opacity-0 transition-opacity md:group-hover:opacity-100 z-20"
        ></div>

        <!-- Viewer Count -->
        <div
            class="absolute top-0 w-full left-0 h-[44px] p-2 text-white items-center flex flex-row md:group-hover:opacity-0 dark-shadow-bg transition-opacity z-20"
        >
            <img src="assets/images/shared/icons/watcher-icon.svg" alt="viewers" />
            <span class="font-normal text-xs px-1 py-0.5">{{ data.participantsCount }}</span>
            <div
                *ngIf="!data.isMicActive || !data.isCameraActive"
                class="border-[1px] border-opacity-40 min-h-4 border-white mx-1"
            ></div>
            <img
                *ngIf="!data.isCameraActive"
                src="assets/images/shared/icons/video-off.svg"
                alt="cam off"
                class="size-4"
            />
            <img *ngIf="!data.isMicActive" src="assets/images/shared/icons/mic-off.svg" alt="mic off" class="size-4" />
        </div>

        <!-- User Profile and Name Section -->
        <div
            class="absolute bottom-0 left-0 p-3 text-white md:group-hover:opacity-0 dark-shadow-bottom-bg transition-opacity z-20 w-full"
        >
            <div class="max-w-full flex items-end gap-1">
                <!--- LOTTIE ANIMATION --->
                <ng-lottie [options]="lottieConfig" />

                <app-user-level [userLevel]="data.level" />

                <!-- User Name -->

                <p class="font-medium inline-block text-sm truncate">{{ data.fullName }}</p>
            </div>
        </div>

        <!-- Centered Play Button -->
        <div
            class="hidden absolute inset-0 items-center justify-center transform scale-200 md:group-hover:scale-150 transition duration-200 z-20 md:flex"
            [ngClass]="{'opacity-0 md:group-hover:opacity-100': isHovering, 'opacity-0 md:opacity-0': !isHovering}"
        >
            <span class="text-white">
                <svg class="w-10 h-10" fill="currentColor" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                    <path
                        fill-rule="evenodd"
                        d="M9.4 8.477V23.28c0 1.928 2.108 3.114 3.756 2.113l11.514-6.995c1.539-.935 1.594-3.149.104-4.16L13.26 6.43c-1.642-1.113-3.86.063-3.86 2.047"
                        clip-rule="evenodd"
                    ></path>
                </svg>
            </span>
        </div>

        <!--- Premium Icon ---->
        <div
            *ngIf="!data.isFreemium"
            class="absolute bg-black top-1/2 left-1/2 transform translate-x-[-50%] translate-y-[-50%] rounded-full bg-opacity-25 w-20 h-20 flex items-center group justify-center md:group-hover:opacity-0 transition-opacity z-20 backdrop-blur-2xl"
        >
            <img src="assets/images/shared/icons/lock.svg" alt="premium" class="w-10 h-10" />
        </div>
    </div>
</div>
