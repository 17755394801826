import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-user-level',
    standalone: true,
    imports: [],
    templateUrl: './user-level.component.html',
    styleUrl: './user-level.component.scss',
})
export class UserLevelComponent {
    @Input() userLevel!: number;
}
