import {CommonModule} from '@angular/common';
import {Component, Input} from '@angular/core';
import {Room} from '@core/models/candidate/room';
import convertCoin from '@core/utils/helpers/coinConverter';
import userPhotoConverter from '@core/utils/helpers/userPhotoConverter';
import {AnimationOptions, LottieComponent} from 'ngx-lottie';
import {UserLevelComponent} from '@core/components/ui-components/user-level/user-level.component';

@Component({
    selector: 'app-streamer-card',
    standalone: true,
    imports: [CommonModule, LottieComponent, UserLevelComponent],
    templateUrl: './streamer-card.component.html',
    styleUrl: './streamer-card.component.scss',
})
export class StreamerCardComponent {
    @Input() data!: Room;

    lottieConfig: AnimationOptions = {
        path: 'assets/lotties/liveCell.json',
    };
    isHovering: boolean = false;
    protected readonly convertCoin = convertCoin;
    protected readonly userPhotoConverter = userPhotoConverter;
}
